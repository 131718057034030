import { Loader } from '@mantine/core';
import { motion } from 'framer-motion';
import jwt from 'jwt-decode';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import useInput from '../../hooks/useInput';
import { authActions } from '../../store/slice/authSlice';

const Login = ({ setIsRegister, setIsPasswordReminder }) => {
	const dispatch = useDispatch();
	const [isFormValid, setIsFormValid] = useState(null);
	const {
		value: enteredLogin,
		hasError: enteredLoginHasError,
		enteredValueChangeHandler: enteredLoginChangeHandler,
	} = useInput((enteredValue) =>
		enteredValue?.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		)
	);

	const {
		value: enteredPassword,
		hasError: enterePasswordeHasError,
		enteredValueChangeHandler: enteredPasswordChangeHandler,
	} = useInput((enteredValue) => enteredValue.length > 5);

	const loginClient = async ({ email, password }) => {
		await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
			method: 'POST',
			body: JSON.stringify({
				email,
				password,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				if (response.status === 401) {
					throw new Error('Twoje dane są nieprawidłowe. Spróbuj ponownie.');
				}
				if (response.ok) {
					return response.json();
				}
			})
			.then((data) => {
				const user = jwt(data.token);

				if (
					user?.accountType === 'publisher' &&
					user.isActive &&
					!user.isBlocked
				) {
					localStorage.setItem('token', data.token);
					localStorage.setItem('refreshToken', data.refresh_token);
					dispatch(authActions.logIn(user));
				} else if (!user.isActive) {
					throw new Error('Konto jest nieaktywne!');
				} else if (user.isBlocked) {
					throw new Error(
						'Twoje konto zostało zablokowane. Skontaktuj się z administratorem.'
					);
				} else {
					throw new Error('Twoje dane są nieprawidłowe. Spróbuj ponownie.');
				}
			});
	};

	const loginClientMutate = useMutation(loginClient);

	const loginHandler = (e) => {
		e.preventDefault();
		if (enterePasswordeHasError || enteredLoginHasError) {
			setIsFormValid(false);
		}
		if (!enterePasswordeHasError && !enteredLoginHasError) {
			loginClientMutate.mutate({
				email: enteredLogin,
				password: enteredPassword,
			});
		}
	};

	return (
		<motion.div
			initial={{ opacity: 0, y: 5 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{
				default: {
					duration: 0.3,
				},
			}}
			className="w-100"
		>
			<h2 className="mp-flex-white__title">
				Zaloguj się jako <br />
				zamieszczający
			</h2>

			<form onSubmit={loginHandler}>
				<div className="form__group">
					<div className="form__field">
						<input
							onChange={(e) => enteredLoginChangeHandler(e)}
							type="email"
							name="f-email-3"
							placeholder="Adres e-mail"
							className="form__input"
							id="f-email-3"
						/>
					</div>
				</div>

				<div className="form__group">
					<div className="form__field">
						<input
							onChange={(e) => enteredPasswordChangeHandler(e)}
							type="password"
							name="f-pass-3"
							placeholder="Hasło"
							className="form__input"
							id="f-pass-3"
						/>
					</div>
				</div>

				<div className="form__actions mb-20">
					<button className="btn btn--block">
						{loginClientMutate.isLoading ? (
							<div>
								<Loader size={12} color={`#ffffff`} />
							</div>
						) : (
							<div>Zaloguj się</div>
						)}
					</button>
				</div>
				{isFormValid === false && (
					<motion.div
						initial={{ opacity: 0, y: 5 }}
						animate={{ opacity: 1, y: 0 }}
						className="form__actions mb-20"
					>
						<p className="error">Uzupełnij dane.</p>
					</motion.div>
				)}
				{loginClientMutate.isError && (
					<motion.div
						initial={{ opacity: 0, y: 5 }}
						animate={{ opacity: 1, y: 0 }}
						className="form__actions mb-20"
					>
						<p className="error">{loginClientMutate.error.message}</p>
					</motion.div>
				)}

				<div className="form__group">
					<div className="main-wrapper">
						<div className="account-info-check">
							<span>Zapomniałes hasła?</span>
							<button
								onClick={() => {
									setIsPasswordReminder(true);
								}}
								type={'button'}
							>
								Przypomnij hasło
							</button>
						</div>
						<div className="account-info-check">
							<span>Nie posiadasz konta?</span>
							<button onClick={() => setIsRegister(true)}>
								Zarejestruj się
							</button>
						</div>
					</div>
				</div>
			</form>
		</motion.div>
	);
};

export default Login;
