import React, { useState } from 'react';
import { Loader, LoadingOverlay, Modal, Tooltip } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { groupBy, pick } from 'lodash';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { getImageGroupCategory } from '../../api/get';
import { authorizedGet } from '../../fetchFunctions';
import { newMaterialActions } from '../../store/slice/newMaterialSlice';

const conditionCodes = [
	{
		name: 'U - Uproszczone warunki pobrania',
		code: [],
	},
	{
		name: 'C - Cyfrowa Reprodukcja Elektroniczna',
		code: ['C_DC_DER'],
	},
	{
		name: 'D - Reprodukcja Drukowana',
		code: ['C_DC_PAP'],
	},
	{
		name: 'K - Kampanie reklamowe w miejscach  publicznych',
		code: ['C_DC_ACP'],
	},
	{
		name: 'F - Multimedialne osadzenie',
		code: ['C_DC_ME'],
	},
	{
		name: 'T - Na towary przeznaczone do sprzedaży',
		code: ['C_DC_GIS'],
	},
	{
		name: 'Z - Na nadruki na znaki specyficzne',
		code: ['C_DC_OSS'],
	},
	{
		name: 'S - Na specyficzne szablony elektroniczne',
		code: ['C_DC_SET'],
	},
	{
		name: 'P - Na plakaty do celów dekoracyjnych',
		code: ['C_DC_PDP'],
	},
	{
		name: 'N - Nieograniczona Publikacja',
		code: ['C_DC_UP'],
	},
	{
		name: 'M - Medialne Pobieranie',
		code: ['M_DC_M'],
	},
];

const ImageGroupRow = ({ imageGroup, img, setChosenMenuItem }) => {
	const [openedDescription, setOpenedDescription] = useState(false);
	const [openedCondition, setOpenedCondition] = useState(false);
	const [openBigPicture, setOpenBigPicture] = useState(false);
	const [zoom, setZoom] = useState(false);
	const dispatch = useDispatch();

	const { data: imageGroupCategory } = useQuery(
		'image_group_categories',
		() => authorizedGet('/image_group_categories?exists[parentCategory]=false'),
		{
			placeholderData: [],
		}
	);

	const imageGroupQuery = useQuery(
		['image_groups', imageGroup.id],
		getImageGroupCategory,
		{
			enabled: openedCondition || openedDescription,
		}
	);

	const [clickedItem, setClickedItem] = useState(null);

	const { data: downloadConditions } = useQuery(
		'downloadConditions',
		() => authorizedGet('/setting_licence_pricings?pagination=false'),
		{
			initialData: {},
			select: (dc) =>
				groupBy(dc, (item) => {
					return item.code.split('_').slice(0, 3).join('_');
				}),
		}
	);

	return (
		<>
			<tr>
				<td className="uploaded-materials-table__td  td-picture">
					<span className="uploaded-materials-table__rwd-label">Obraz</span>
					<figure
						onClick={() => setOpenBigPicture(img.id)}
						className="img"
						style={{ position: 'relative' }}
					>
						<LoadingOverlay visible={!img?.thumbnailWatermarkImage} />
						<a href="#/" className="js-magnific">
							<img src={img?.thumbnailWatermarkImage?.contentUrl} alt="" />
						</a>
					</figure>
					<AnimatePresence>
						{openBigPicture === img.id && (
							<>
								<div
									onClick={() => {
										setZoom(false);
										setOpenBigPicture(false);
									}}
									className={'custom-modal-img-background'}
								></div>
								<figure
									onClick={() => setOpenBigPicture(img.id)}
									className={`img active ${zoom ? 'zoom' : ''}`}
									style={{
										display: 'flex',
										maxWidth: '60vw',
										justifyContent: 'center',
									}}
								>
									<a href="#/" className="js-magnific">
										<motion.img
											initial={{
												scale: 0,
												opacity: 0,
												x: -400,
												y: 100,
											}}
											animate={{
												scale: 1,
												opacity: 1,
												x: 0,
												y: 0,
											}}
											exit={{
												scale: 0,
												opacity: 0,
												x: -200,
												y: 100,
											}}
											transition={{
												duration: 0.4,
											}}
											style={{
												objectFit: 'contain',
											}}
											onClick={() => setZoom((prevState) => !prevState)}
											src={img?.originalImage?.contentUrl}
											alt=""
										/>
									</a>
								</figure>
								<div className="custom-fancybox-toolbar">
									<button
										onClick={() => setZoom((prevState) => !prevState)}
										className="fancybox-button fancybox-button--zoom"
										title="Powiększ"
									>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
											<path d="M18.7 17.3l-3-3a5.9 5.9 0 0 0-.6-7.6 5.9 5.9 0 0 0-8.4 0 5.9 5.9 0 0 0 0 8.4 5.9 5.9 0 0 0 7.7.7l3 3a1 1 0 0 0 1.3 0c.4-.5.4-1 0-1.5zM8.1 13.8a4 4 0 0 1 0-5.7 4 4 0 0 1 5.7 0 4 4 0 0 1 0 5.7 4 4 0 0 1-5.7 0z"></path>
										</svg>
									</button>
									<button
										onClick={() => setOpenBigPicture(false)}
										className="fancybox-button fancybox-button--close"
										title="Zamknij"
									>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
											<path d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"></path>
										</svg>
									</button>
								</div>
							</>
						)}
					</AnimatePresence>
				</td>
				<td className="uploaded-materials-table__td td-number">
					<span className="uploaded-materials-table__rwd-label">
						NR ZESTAWU
					</span>
					{imageGroup?.insideId}
				</td>
				<td className="uploaded-materials-table__td td-id">
					<span className="uploaded-materials-table__rwd-label">
						NR OBRAZU W ZESTAWIE
					</span>
					{img?.orderImage + 1}
				</td>
				<td className="uploaded-materials-table__td">
					<span className="uploaded-materials-table__rwd-label">
						DATA WYKONANIA
					</span>
					{moment(imageGroup?.publisherImageGroupCreatedAt).format(
						'DD.MM.YYYY'
					)}
				</td>
				<td className="uploaded-materials-table__td td-button">
					<span className="uploaded-materials-table__rwd-label">
						OPIS ZESTAWU
					</span>
					<a
						onClick={(e) => {
							e.preventDefault();
							setOpenedDescription(true);
						}}
						href="/"
						className="table-action-button"
					>
						Zobacz
					</a>
				</td>
				<td className="uploaded-materials-table__td td-info">
					<span className="uploaded-materials-table__rwd-label">WIZERUNEK</span>
					{imageGroup?.publisherGodMotherDescription}
				</td>
				<td className="uploaded-materials-table__td td-button">
					<span className="uploaded-materials-table__rwd-label">
						WARUNKI UDOSTĘPNIANIA
					</span>
					<a
						onClick={(e) => {
							e.preventDefault();
							setOpenedCondition(true);
						}}
						href="/"
						className="table-action-button"
					>
						Zobacz
					</a>
				</td>
				<td className="uploaded-materials-table__td td-status">
					<span className="uploaded-materials-table__rwd-label">STATUS</span>
					{getStatus(img.status)}
				</td>
				<td className="uploaded-materials-table__td td-actions">
					<span className="uploaded-materials-table__rwd-label">OPCJE</span>
					<a
						onClick={async (e) => {
							e.preventDefault();

							const imageGropup = (await imageGroupQuery.refetch()).data;

							dispatch(
								newMaterialActions.updateForm({
									...pick(imageGropup, [
										'id',
										'title',
										'publisherImageGroupCreatedAt',
										'publisherAuthor',
										'publisherDescription',
										'publisherGodMotherDescription',
									]),
									publisherPricingGrossCommercial:
										(imageGropup.publisherPricingGrossCommercial || 1) / 100,
									publisherPricingGrossMedia:
										(imageGropup.publisherPricingGrossMedia || 1) / 100,
									publisherPricingGrossSimplified:
										(imageGropup.publisherPricingGrossSimplified || 1) / 100,
									licenceAttributes: imageGropup.licences.map(
										(lic) => lic.settingLicencePricing
									),
									isShared:
										imageGropup.licences.map(
											(e) => e.settingLicencePricing.code
										).length > 0
											? 'custom'
											: 'all',
									mainCategory: imageGroupCategory.find(
										(e) =>
											e.id === imageGropup.selectedCategory.parentCategory.id
									),
									categories: imageGropup?.selectedCategory?.id,
									chosenMainLicences: imageGropup.licences.map((e) =>
										e.settingLicencePricing.code
											.split('_')
											.slice(0, 3)
											.join('_')
									),
								})
							);
							setChosenMenuItem('Dodaj materiał');
						}}
						href="/"
						className="table-action-button"
					>
						{imageGroupQuery.isFetching ? (
							<Loader />
						) : (
							<i className="ico ico-pen"></i>
						)}{' '}
						Edytuj
					</a>
				</td>
			</tr>
			<Modal
				opened={openedDescription}
				withCloseButton={false}
				centered
				onClose={() => setOpenedDescription(false)}
				size="lg"
			>
				<div
					className="modal fancybox-content"
					id="description-modal"
					style={{ display: 'inline-block' }}
				>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							setOpenedDescription(false);
						}}
						className="modal__close js-modal-close"
					>
						<span>Zamknij</span>
						<i className="ico ico-cancel"></i>
					</a>
					<div className="modal__inner">
						<h3 className="modal__title">Opis zestawu</h3>
						{!imageGroupQuery.data ? (
							<Loader />
						) : (
							<p>{imageGroupQuery.data?.publisherDescription}</p>
						)}
					</div>
				</div>
			</Modal>
			<Modal
				opened={openedCondition}
				withCloseButton={false}
				centered
				onClose={() => {
					dispatch(newMaterialActions.resetForm());
					setOpenedCondition(false);
				}}
				size="lg"
			>
				<div className="modal__inner">
					<div className="modal__wrapper">
						<h3 className="modal__title">Warunki pobrania</h3>
					</div>
					{!imageGroupQuery.data ? (
						<Loader />
					) : (
						<div className="blue-box">
							<div className="blue-box__inner" style={{ padding: 50 }}>
								<div className="custom-checkbox-with-accordions-container">
									<div className="accordion js-accordion">
										{conditionCodes?.map((con) => {
											return (
												<div
													key={con?.name}
													className={`custom-checkbox js-accordion-item ${
														con?.name === clickedItem ? 'is-active' : ''
													}`}
												>
													<div
														style={{ display: 'flex', alignItems: 'center' }}
													>
														<label
															className="checkbox "
															htmlFor={`f-custom-checkbox-${con?.name}`}
														>
															<input
																checked={
																	con?.code?.length === 0
																		? imageGroupQuery.data?.licences?.some(
																				(e) =>
																					e?.settingLicencePricing?.code?.includes(
																						'S_MM'
																					)
																		  )
																		: downloadConditions[con?.code[0]]?.filter(
																				(e) =>
																					imageGroupQuery.data.licences.some(
																						(cts) =>
																							cts.settingLicencePricing.code.includes(
																								e.code
																							)
																					)
																		  )?.length > 0
																}
																type="checkbox"
																disabled
																name={`f-custom-checkbox-${con?.name}`}
																id={`f-custom-checkbox-${con?.name}`}
																className="checkbox__input"
															/>
															<span className="checkbox__text custom js-accordion-title">
																<span className="checkbox__label custom">
																	{con?.name}{' '}
																	{downloadConditions[con?.code[0]]?.length > 0
																		? `(${
																				downloadConditions[
																					con?.code[0]
																				]?.filter((e) =>
																					imageGroupQuery.data.licences.some(
																						(cts) =>
																							cts.settingLicencePricing.code ===
																							e.code
																					)
																				)?.length
																		  }/${
																				downloadConditions[con?.code[0]]?.length
																		  })`
																		: null}{' '}
																</span>
															</span>
														</label>
														{con?.code?.length > 0 && (
															<span
																onClick={() => {
																	setClickedItem((prev) => {
																		if (prev === con) {
																			return null;
																		}
																		return con;
																	});
																}}
																style={{
																	cursor: 'pointer',
																	position: 'relative',
																	marginLeft: 'auto',
																	marginRight: 0,
																	fontSize: 10,
																	transition: 'all 0.3s ease-out 0s',
																	transform:
																		con === clickedItem ? 'rotate(180deg)' : '',
																}}
																className="arrow"
															>
																<i className="ico ico-chevron-down-proj"></i>
															</span>
														)}
													</div>

													{con?.name === clickedItem?.name && (
														<div
															className="accordion__content js-accordion-content"
															style={{ display: 'block' }}
														>
															<div className="accordion__content-inner">
																{downloadConditions[con?.code[0]]?.map(
																	(sub, i) => {
																		return (
																			<div
																				key={sub.id}
																				className="form__checkbox"
																			>
																				<label
																					className="checkbox"
																					htmlFor={`f-horizontal-${sub.title}`}
																				>
																					<input
																						checked={imageGroupQuery.data?.licences?.some(
																							(code) =>
																								code?.settingLicencePricing
																									?.code === sub.code
																						)}
																						disabled
																						type="checkbox"
																						name={`f-horizontal-${sub.title}`}
																						id={`f-horizontal-${sub.title}`}
																						className="checkbox__input "
																					/>
																					<span className="checkbox__text">
																						<span className="checkbox__label">
																							{i + 1 + ' - ' + sub.title}
																						</span>
																					</span>
																				</label>
																			</div>
																		);
																	}
																)}
															</div>
														</div>
													)}
												</div>
											);
										})}
									</div>
								</div>

								<div className="main-text wysiwyg">
									<p>
										Zezwalając na pobranie z wyłącznością na użytkowanie Twojego
										materiału umożliwiasz Pobierającemu zablokowanie tego
										materiału dla kolejnych pobrań, jednak my za takie
										wykorzystanie obciąymy go opłatą licencyjną mogącą
										przekroczyć kilkasetkrotnie kwotę opłaty jaką uiściłby za
										pobranie bez wyłączności, co przełoży się u Ciebie na
										kilkusetkrotnie większe honorarium.
									</p>
								</div>

								<div className="row">
									<div className="col-md-9">
										<div
											style={{ padding: 15 }}
											className="custom-radios__item custom-radios__view margin-clear js-custom-radio"
										>
											<label className="checkbox" htmlFor="f-custom-3">
												<input
													disabled
													checked={imageGroupQuery.data?.licences?.some((e) =>
														e?.settingLicencePricing?.code?.includes('C_DC_EU')
													)}
													type="checkbox"
													name="f-custom-3"
													id="f-custom-3"
													className="checkbox__input js-checkbox"
												/>
												<span className="checkbox__text">
													<span className="checkbox__label">
														Zezwalam na pobranie z wyłącznością
													</span>
												</span>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</Modal>
		</>
	);
};

export default ImageGroupRow;

function getStatus(status) {
	let char;
	let color;
	let label;

	switch (status) {
		case 0:
			char = 'O';
			color = 'info';
			label = 'Oczekuje na weryfikację';
			break;
		case 1:
			char = 'D';
			color = 'success';
			label = 'Dopuszczony dla pobrań';
			break;
		case 2:
			char = 'S';
			color = 'danger';
			label = 'Skasowany z serwisu';
			break;
		case 3:
			char = 'Z';
			color = 'warning';
			label = 'Zablokowany dla pobrań';
			break;
		case 4:
			char = 'ZP';
			color = 'pink';
			label = 'Zablokowany przez pobierającego';
			break;
		case 5:
			char = 'UP';
			color = 'dark';
			label = 'Skasowany przez pobierającego';
			break;
		default:
			return <></>;
	}

	return (
		<Tooltip label={label}>
			<div className={`status status--${color}`}>
				<div className="status__dot"></div>
				<div className="status__label">{char}</div>
			</div>
		</Tooltip>
	);
}
