import React from 'react';

const ValueComponent = () => {
    return (
        <span>
            Dodaj obrazy (Format JPG lub ZIP)
        </span>
    );
};

export default ValueComponent;