import { Loader } from '@mantine/core';
import { motion } from 'framer-motion';
import { omit, pick } from 'lodash/object';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
	authorizedImageGroupDataPost,
	authorizedPutWithId,
} from '../../fetchFunctions';
import { newMaterialActions } from '../../store/slice/newMaterialSlice';
import { formatDateForInput } from '../../utils';
import { postImageGroup } from '../../api/post';
import Loading from '../Loading/Loading';

const FourthStep = ({ setStep, setChosenMenuItem }) => {
	const dispatch = useDispatch();
	const form = useSelector((state) => state.newMaterial);
	const [showTooltip, setShowTooltip] = useState(false);
	const [showTooltip2, setShowTooltip2] = useState(false);
	const [showTooltip3, setShowTooltip3] = useState(false);
	const queryClient = useQueryClient();
	const addImageGroupMutation = useMutation(postImageGroup, {
		onSuccess: () => {
			queryClient.invalidateQueries('image_groups');
		},
	});

	const editImageGroupMutation = useMutation(authorizedPutWithId);

	const addAttachmentMutation = useMutation(authorizedImageGroupDataPost);

	useEffect(() => {
		if (addImageGroupMutation.isLoading)
			window.addEventListener('beforeunload', (event) => {
				event.preventDefault();
				event.returnValue = true;
			});
		else
			window.removeEventListener('beforeunload', (event) => {
				event.preventDefault();
				event.returnValue = true;
			});

		return () => {
			window.removeEventListener('beforeunload', (event) => {
				event.preventDefault();
				event.returnValue = true;
			});
		};
	}, [addImageGroupMutation.isLoading]);

	if (addImageGroupMutation.isLoading)
		return (
			<Loading
				stylez={{
					left: 0,
				}}
			/>
		);

	return (
		<div className="white-box white-box--library">
			<div className="white-box__inner">
				<h3 className="medium-title">
					Określ bazową opłatę licencyjną za Twój materiał
				</h3>
				<div className="main-text wysiwyg">
					<p>
						Nie określaj zbyt wysokiej bazowej opłaty licencyjnej za pobranie.
						Pozwól menadżerowi Serwisu podnieść ją do takiej wartości, przy
						której Twoje honorariom z przeliczenia ilości pobrań i jednostkowej
						opłaty będzie największe. Zdaj się na doświadczenie menadżera. Jeśli
						ustawisz zbyt wysoką opłatę menadżer nie będzie mógł ją obniżyć, a
						Twój materiał przy takiej opłacie może nie znaleźć chętnych do
						pobrania i wykorzystania. <br />
					</p>
				</div>

				<form className="form mt-30 js-validate">
					<div className="row">
						<div className="col-md-6">
							<h3 className="main-title-heading">OPŁATY LICENCYJNE BRUTTO</h3>
							<div className="form__group">
								<div className="form__field">
									<div className="input-container">
										<input
											readOnly={!!form.id}
											defaultValue={form.publisherPricingGrossCommercial}
											style={{ fontSize: '20px !important' }}
											onChange={(e) =>
												dispatch(
													newMaterialActions.updateForm({
														publisherPricingGrossCommercial:
															+e.target.value * 100,
													})
												)
											}
											type="number"
											name="f-comerce"
											placeholder="Opłata bazowa licencyjna dla pobrań komercyjnych"
											className="form__input form__input-custom required input-placeholder-sm"
											id="f-comerce"
										/>
										<a
											href="/"
											onMouseEnter={() => setShowTooltip(true)}
											onMouseLeave={() => setShowTooltip(false)}
											className="floating-icon js-tooltip tooltipstered"
										>
											<i className="ico ico-tooltip">
												<span className="path1"></span>
												<span className="path2"></span>
											</i>
											{showTooltip && (
												<div className="custom-tooltip">
													Dla pobrania bez wyłączności, dla dystrybucji najmniej
													efektywnej, w skromnym miejscu ulokowania, <br />o
													minimalnym zasięgu terytorialnym, minimalnym czasie
													wykorzystania i minimalnej reprodukcji
												</div>
											)}
										</a>
									</div>
								</div>
							</div>
							<div className="form__group">
								<div className="form__field">
									<div className="input-container">
										<input
											readOnly={!!form.id}
											defaultValue={form.publisherPricingGrossMedia}
											onChange={(e) =>
												dispatch(
													newMaterialActions.updateForm({
														publisherPricingGrossMedia: +e.target.value * 100,
													})
												)
											}
											type="number"
											name="f-media"
											placeholder="Opłata bazowa licencyjna dla pobrań medialnych"
											className="form__input form__input-custom required input-placeholder-sm"
											id="f-media"
										/>
										<a
											href="/"
											onMouseEnter={() => setShowTooltip2(true)}
											onMouseLeave={() => setShowTooltip2(false)}
											className="floating-icon js-tooltip tooltipstered"
										>
											<i className="ico ico-tooltip">
												<span className="path1"></span>
												<span className="path2"></span>
											</i>
											{showTooltip2 && (
												<motion.div
													initial={{ opacity: 0 }}
													animate={{ opacity: 1 }}
													transition={{
														default: {
															duration: 0.3,
														},
													}}
													className="custom-tooltip"
												>
													Dla pobrania bez wyłączności, przy pojedynczej
													interpretacji, <br />o minimalnym zasięgu
													terytorialnym, minimalnym czasie wykorzystania i
													minimalnej liczbie reprodukcji
												</motion.div>
											)}
										</a>
									</div>
								</div>
							</div>
							<div className="form__group">
								<div className="form__field">
									<div className="input-container">
										<input
											readOnly={!!form.id}
											defaultValue={form.publisherPricingGrossSimplified}
											onChange={(e) =>
												dispatch(
													newMaterialActions.updateForm({
														publisherPricingGrossSimplified:
															+e.target.value * 100,
													})
												)
											}
											type="number"
											name="f-simplified"
											placeholder="Opłata bazowa licencyjna dla pobrań uproszczonych"
											className="form__input-custom form__input required input-placeholder-sm"
											id="f-simplified"
										/>
										<a
											href="/"
											onMouseEnter={() => setShowTooltip3(true)}
											onMouseLeave={() => setShowTooltip3(false)}
											className="floating-icon js-tooltip tooltipstered"
										>
											<i className="ico ico-tooltip">
												<span className="path1"></span>
												<span className="path2"></span>
											</i>
											{showTooltip3 && (
												<motion.div
													initial={{ opacity: 0 }}
													animate={{ opacity: 1 }}
													transition={{
														default: {
															duration: 0.3,
														},
													}}
													className="custom-tooltip"
												>
													Dla pobrania w wymiarze oryginalnym, przy jednej
													osobie uprawnionej do wykorzystania
												</motion.div>
											)}
										</a>
									</div>
								</div>
							</div>

							<div className="form__actions mt-30">
								<button
									onClick={() => {
										if (form.id) {
											editImageGroupMutation.mutate(
												{
													url: '/image_groups',
													data: {
														...pick(form, [
															'publisherPricingGrossSimplified',
															'publisherPricingGrossCommercial',
															'publisherPricingGrossMedia',
															'publisherDescription',
															'publisherGodMotherDescription',
															'verifiedBy',
															'title',
														]),
														publisherAuthor: form.publisherAuthor,
														categories: form?.categories
															? [form?.categories]
															: [],
														topic: form?.topic,
														publisherImageGroupCreatedAt: formatDateForInput(
															form.publisherImageGroupCreatedAt
														),
													},
													id: form?.id,
												},
												{
													onSuccess: (d) => {
														if (form.imageUrl.length > 0) {
															form.imageUrl.forEach((image, index) => {
																const fd = new FormData();
																fd.append('file', image);

																addAttachmentMutation.mutate({
																	url: '/attachments',
																	data: fd,
																	imageGroupData: d,
																	orderImage: index,
																});
															});
														}

														setTimeout(() => {
															setChosenMenuItem('Zamieszczone materiały');
															setStep(1);
														}, 2000);
														dispatch(newMaterialActions.resetForm());
													},
												}
											);
										} else {
											addImageGroupMutation.mutate(
												{
													payload: {
														...omit(form, ['mainCategory', 'imageUrl']),
														publisherAuthor: form.publisherAuthor,
														categories: form?.categories
															? [form?.categories]
															: [],
														topic: form?.topic,
														publisherOrderIncomePercent: 33,
														publisherImageGroupCreatedAt: formatDateForInput(
															form?.publisherImageGroupCreatedAt || new Date()
														),
													},
													files: form.imageUrl,
												},
												{
													onSuccess: (d) => {
														setStep((prev) => prev + 1);
														dispatch(newMaterialActions.resetForm());
													},
												}
											);
										}
									}}
									type="button"
									className="btn btn--block"
								>
									{addImageGroupMutation.isLoading ? (
										<div>
											<Loader size={14} />
										</div>
									) : (
										'Zapisz i zakończ'
									)}
								</button>
							</div>
							{editImageGroupMutation.isSuccess && (
								<p
									style={{
										color: 'green',
										textAlign: 'center',
										marginBottom: 0,
										marginTop: 20,
									}}
								>
									Zapisano!
								</p>
							)}
						</div>

						<div className="col-md-6 col-xlg-5 col-xlg-offset-1 last-xlg last-lg last-md first-sm first-xs first-xxs">
							<h3 className="main-title-heading">TWÓJ PROCENT POBRANIA</h3>
							<div className="download-percent-info">
								<div className="download-percent-info__percent">33%</div>
								<div className="download-percent-info__text">
									<i className="ico ico-info-icon"></i>
									<span className="text">
										W chwili, w której pobierający materiał dokona pobrania z
										Twojego zamieszczenia, Ty nabywasz uprawnienie do honorarium
										autorskiego w wysokości ustalonej procentowo od kwoty, na
										którą wniesiono opłatę licencyjną za dokonane pobranie. Twój
										procent z pobrania ustalony został przez menadżera serwisu.
										Możesz go negocjować w kontakccie bezpośrednim z mendażerem.
									</span>
								</div>
							</div>
						</div>
					</div>
				</form>
				<a
					href="/"
					onClick={(e) => {
						e.preventDefault();
						setStep((prev) => prev - 1);
					}}
					className="return-button mt-20"
				>
					<i className="ico ico-long-arrow-left-icon"></i>{' '}
					<span>Powrót do miejsca zamieszczenia</span>
				</a>
			</div>
		</div>
	);
};

export default FourthStep;
