import React from 'react';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './App.scss';
import Base from './components/Base/Base';

const queryClient = new QueryClient();

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<MantineProvider
				theme={{
					components: {
						Tooltip: {
							defaultProps: {
								withArrow: true,
								arrowSize: 12,
							},
							styles: {
								tooltip: {
									backgroundColor: '#364a59',
									fontSize: 12,
									padding: 12,
								},
							},
						},
						TextInput: {
							defaultProps: {
								unstyled: true,
							},
							classNames: {
								input: 'form__input',
							},
							styles: {
								error: {
									color: 'red',
									marginTop: 5,
									fontSize: 12,
									textAlign: 'center',
								},
								invalid: {
									borderColor: 'red',
									color: 'red',
								},
							},
						},
					},
				}}
			>
				<NotificationsProvider>
					<div className="App">
						<Base />
					</div>
				</NotificationsProvider>
			</MantineProvider>
			<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
		</QueryClientProvider>
	);
}

export default App;
