import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/slice/authSlice';
import { useQuery } from 'react-query';
import { getContents, getMailQueues } from '../../api/get';
import { newMaterialActions } from '../../store/slice/newMaterialSlice';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

const SubHeader = ({ setChosenMenuItem, chosenMenuItem }) => {
	const user = useSelector((state) => state.auth.user);
	const userLogin = user?.email.split('@')[0];
	const userName = user?.userDisplayName.split(' ')[0];
	const dispatch = useDispatch();

	const [opened, controls] = useDisclosure(false);

	const contentsQuery = useQuery(['setting_contents'], getContents, {
		initialData: [],
		select: (data) =>
			data.reduce((acc, curr) => {
				acc[curr.insideKey] = curr.content;
				return acc;
			}, {}),
	});

	return (
		<div className="subheader fixed-menu2">
			<div className="container">
				<div
					className="panel"
					style={{
						paddingInline: 33,
						paddingBlock: 12,
						display: 'flex',
						justifyContent: 'space-between',
						height: 'unset',
					}}
				>
					<a
						onClick={(e) => {
							e.preventDefault();
							setChosenMenuItem('Zamieszczone materiały');
						}}
						href="/"
						className="panel__item"
						style={{
							margin: 0,
						}}
					>
						<i className="ico ico-user-icon"></i>
						<span className="panel__item-name">
							Zalogowany jako:{' '}
							<span className="yellow">{userLogin || userName}</span>
						</span>
					</a>
					<div
						style={{
							display: 'flex',
						}}
					>
						<div
							style={{
								display: 'flex',
								gap: 12,
							}}
						>
							<a
								onClick={controls.open}
								href="#"
								className="btn btn--outline"
								style={{
									padding: '0 12px',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								Warunki
								<br />
								dla zamieszczeń
							</a>
							<a
								onClick={() => setChosenMenuItem('Dodaj fakturę')}
								href="#"
								className="btn btn--outline"
								style={{
									padding: '0 12px',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								+ Dodaj dokument
								<br /> księgowy
							</a>
							<a
								onClick={() => {
									dispatch(newMaterialActions.resetForm());
									setChosenMenuItem('Dodaj materiał');
								}}
								href="#"
								className="btn"
								style={{
									paddingInline: 12,
								}}
							>
								+ Dodaj materiał
							</a>
						</div>

						<div className="panel__nav">
							<a
								onClick={(e) => {
									e.preventDefault();
									setChosenMenuItem('Powiadomienia');
								}}
								href="/"
								className="panel__item "
							>
								<span className="label-icon">
									<i className="ico ico-bell-icon"></i>
									{false && <span className="label">2</span>}
								</span>

								<span className="panel__item-name hidden-sm">
									Powiadomienia
								</span>
							</a>

							<a
								href="/"
								onClick={(e) => {
									e.preventDefault();
									dispatch(authActions.logOut());
								}}
								className="panel__item hidden-sm"
							>
								<i className="ico ico-log-off"></i>
								<span className="panel__item-name">Wyloguj</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<Modal
				opened={opened}
				withCloseButton={false}
				centered
				onClose={controls.close}
				size="lg"
			>
				<div className="modal__inner">
					<div className="modal__wrapper">
						<h3 className="modal__title">
							{contentsQuery.data['publisher.downloadConditionModal.title']}
						</h3>
						<a
							onClick={controls.close}
							className="modal__close-button js-modal-close"
						>
							<i className="ico ico-close-icon"></i>
						</a>
					</div>
					<div className="modal__content">
						<div
							className="wysiwyg"
							dangerouslySetInnerHTML={{
								__html:
									contentsQuery.data[
										'publisher.downloadConditionModal.content'
									],
							}}
						></div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default SubHeader;
