import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useQuery } from 'react-query';
import { authorizedGet } from '../../fetchFunctions';
import { Modal } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { libraryActions } from '../../store/slice/librarySlice';
import { showNotification } from '@mantine/notifications';
import LibraryItem from './LibraryItem';

const Libary = ({ chosenCat, setChosenCat }) => {
	const { data: clientLibrary, isLoading } = useQuery('clientLibrary', () =>
		authorizedGet('/client_libraries')
	);
	const [opened, setOpened] = useState(false);
	const items = useSelector((state) => state.library.items);
	const dispatch = useDispatch();
	const categories = useSelector((state) => state.library.categories);
	const [chosenImg, setChosenImg] = useState(undefined);
	const [chosenCatToChange, setChosenCatToChange] = useState(undefined);
	const [newCatTitle, setNewCatTitle] = useState(undefined);
	const [openEditTitle, setOpenEditTitle] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const [filteredItems, setFilteredItems] = useState(items);

	useEffect(() => {
		const filtered = items.filter((i) =>
			chosenCat
				? chosenCat.title !== 'Wszystkie'
					? i?.image?.category.includes(chosenCat?.title)
					: true
				: true
		);
		setFilteredItems(filtered);
	}, [chosenCat]);

	return (
		<motion.div
			initial={{ opacity: 0, y: 5 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{
				default: {
					duration: 0.3,
				},
			}}
			className="white-box white-box--library"
		>
			<div className="white-box__inner">
				<div className="white-box__wrapper">
					{!openEditTitle && (
						<h2 className="main-title">
							{chosenCat?.title ? chosenCat?.title : 'Wszystkie'}
						</h2>
					)}
					{chosenCat && !openEditTitle && (
						<a
							onClick={() => setOpenEditTitle(true)}
							href="#"
							className="function-button"
						>
							<i className="ico ico-pen"></i> Zmień nazwę
						</a>
					)}
					{openEditTitle && (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<input
								type="text"
								onChange={(e) => setNewCatTitle(e.target.value)}
							/>
							<a
								onClick={() => {
									const filteredImages = items.filter((i) =>
										i?.image?.category.includes(chosenCat?.title)
									);
									filteredImages.forEach((fI) => {
										dispatch(
											libraryActions.changeImgCategory({
												id: fI.id,
												category: newCatTitle,
											})
										);
									});
									dispatch(
										libraryActions.changeCatName({
											cat: chosenCat.title,
											title: newCatTitle,
										})
									);

									setTimeout(() => {
										setChosenCat({
											id: chosenCat.id,
											title: newCatTitle,
										});
										setOpenEditTitle(false);
									}, 1000);
								}}
								href="#"
								className="function-button"
							>
								Zapisz
							</a>
						</div>
					)}
					{chosenCat?.title !== 'Wszystkie' && chosenCat !== undefined && (
						<a
							style={{
								marginLeft: 'auto',
								color: 'red',
								display: 'flex',
								alignItems: 'start',
							}}
							onClick={() => setOpenDelete(true)}
							href="#"
							className="function-button"
						>
							<i className="ico ico-trash"></i> Usuń kategorię
						</a>
					)}
				</div>
				{filteredItems.map((purchase) => {
					return (
						<LibraryItem
							purchase={purchase}
							setOpened={setOpened}
							setChosenImg={setChosenImg}
						/>
					);
				})}
			</div>
			<Modal
				opened={opened}
				withCloseButton={false}
				centered
				onClose={() => setOpened(false)}
				size="35%"
			>
				<div
					className="modal fancybox-content"
					id="category-modal"
					style={{ display: 'inline-block' }}
				>
					<a className="modal__close js-modal-close">
						<span>Zamknij</span>
						<i className="ico ico-cancel"></i>
					</a>
					<div className="modal__inner">
						<h3 className="modal__title">Przenieś do innej kategorii</h3>
						<form
							action="test"
							method="post"
							className="form js-validate"
							noValidate="novalidate"
						>
							<select
								onChange={(e) => {
									setChosenCatToChange(e.target.value);
								}}
								type="text"
								name="f-cat"
								placeholder="Wpisz nazwę kategorii"
								className="form__input required"
								id="f-cat"
							>
								<option value="">Wybierz kategorię...</option>
								{categories
									.filter((c) => c.title !== chosenImg?.image?.category)
									.map((cat) => {
										return <option value={cat.title}>{cat.title}</option>;
									})}
							</select>
						</form>
						<a
							onClick={() => {
								dispatch(
									libraryActions.changeImgCategory({
										id: chosenImg.id,
										category: chosenCatToChange,
									})
								);
								setTimeout(() => setOpened(false), 1000);
							}}
							className="sidenav__item sidenav__item--add-category btn btn--primary"
						>
							+ Przenieś
						</a>
					</div>
				</div>
			</Modal>
			<Modal
				opened={openDelete}
				withCloseButton={false}
				centered
				onClose={() => setOpenDelete(false)}
				size="35%"
			>
				<div
					className="modal fancybox-content"
					id="category-modal"
					style={{ display: 'inline-block' }}
				>
					<a className="modal__close js-modal-close">
						<span>Zamknij</span>
						<i className="ico ico-cancel"></i>
					</a>
					<div className="modal__inner">
						<h3 className="modal__title">
							Czy na pewno chcesz usunąć kategorię?
						</h3>
						<p>Wszystkie zdjęcia z danej kategorii zostną usunięte!</p>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'space-between',
								gap: 20,
							}}
						>
							<a
								onClick={() => {
									dispatch(libraryActions.deleteCat(chosenCat.id));
									setTimeout(() => setOpenDelete(false), 1000);
									setChosenCat({
										id: 1,
										title: 'Wszystkie',
									});
								}}
								className="sidenav__item sidenav__item--add-category btn btn--primary"
							>
								Usuń
							</a>
							<a
								onClick={() => {
									setOpenDelete(false);
								}}
								className="sidenav__item sidenav__item--add-category btn btn--danger"
							>
								Anuluj
							</a>
						</div>
					</div>
				</div>
			</Modal>
		</motion.div>
	);
};

export default Libary;
