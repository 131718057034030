import React from 'react';
import { useMutation } from 'react-query';
import useInput from '../../hooks/useInput';
import { LoadingOverlay } from '@mantine/core';
import axios from 'axios';

async function createRequest({ email }) {
	await axios.post(
		`${process.env.REACT_APP_API_URL}/reset_password/create_request`,
		{
			email,
			redirectUrl: `${window.location.origin}/set-password`,
			accountType: 'publisher',
		}
	);

	return 1;
}

const PasswordReminder = ({ setIsPasswordReminder }) => {
	const {
		value: enteredEmail,
		enteredValueChangeHandler: enteredEmailChangeHandler,
	} = useInput((enteredValue) =>
		enteredValue?.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		)
	);

	const passwordReminderMutation = useMutation(createRequest);

	const passwordReminderHandler = (e) => {
		e.preventDefault();
		passwordReminderMutation.mutate({
			email: enteredEmail,
		});
	};

	return (
		<div>
			<h2 className="mp-flex-white__title">Przypomnienie hasła</h2>
			<div className="mp-flex-white__subtitle">
				Wpisz adres e-mail wpisany podczas rejestracji. Wyślemy na niego
				instrukcję zmiany hasła.
			</div>
			<form onSubmit={passwordReminderHandler}>
				<LoadingOverlay visible={passwordReminderMutation.isLoading} />
				<div className="form__group">
					<div className="form__field">
						<input
							onChange={(e) => enteredEmailChangeHandler(e)}
							type="email"
							name="f-email-4"
							placeholder="Adres e-mail"
							className="form__input"
							id="f-email-4"
						/>
					</div>
				</div>
				{passwordReminderMutation.isError && (
					<div className="form__actions">
						<p className="error" style={{ marginBottom: 12 }}>
							W systemie nie istnieje konto o podanym adresie e-mail
						</p>
					</div>
				)}
				{passwordReminderMutation.isSuccess && (
					<div className="form__actions">
						<p className="success" style={{ marginBottom: 12 }}>
							Na Twoje konto wysłaliśmy instruckję resetu hasła
						</p>
					</div>
				)}
				<div className="form__actions mb-20">
					<button type={'submit'} className="btn btn--block">
						Przypomnij hasło
					</button>
				</div>
				<div className="form__group">
					<div className="account-info-check">
						<button onClick={() => setIsPasswordReminder(false)}>
							Wróć do logowania
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default PasswordReminder;
