import { authorizedFetch } from './authorizedFetch';

export const postPublisherInvoice = async (payload) => {
	const attachmentBody = new FormData();
	attachmentBody.append('file', payload.file);

	const attachResponse = await authorizedFetch(
		'attachments',
		{
			method: 'POST',
			body: attachmentBody,
			headers: {
				Accept: 'application/json',
			},
		},
		true
	);

	const attachedFile = attachResponse.id;

	const response = await authorizedFetch('publisher_invoices', {
		method: 'POST',
		body: JSON.stringify({ ...payload, attachedFile }),
	});

	return await response;
};

export const postImageGroup = async ({ payload, files }) => {
	const response = await authorizedFetch('image_groups', {
		method: 'POST',
		body: JSON.stringify(payload),
	});

	await Promise.all(
		Object.entries(files).map(([idx, file]) => {
			const fd = new FormData();
			fd.append('file', file);

			return authorizedFetch(
				'attachments',
				{
					method: 'POST',
					body: fd,
					'Content-Type': undefined,
				},
				true
			).then((attachmentResponse) => {
				return authorizedFetch('images', {
					method: 'POST',
					body: JSON.stringify({
						originalImage: attachmentResponse.id,
						imageGroup: response.id,
						orderImage: Number(idx),
					}),
				});
			});
		})
	);

	return await response;
};

export const postConversation = async (payload) => {
	const response = await authorizedFetch('conversations', {
		method: 'POST',
		body: JSON.stringify({
			title: payload.title,
			publisherReceiver: payload.publisherReceiver,
		}),
	});

	const attachments = await Promise.all(
		payload.files.map((file) => {
			const attachmentBody = new FormData();
			attachmentBody.append('file', file);

			return authorizedFetch(
				'attachments',
				{
					method: 'POST',
					body: attachmentBody,
					'Content-Type': undefined,
				},
				true
			);
		})
	);

	await authorizedFetch('messages', {
		method: 'POST',
		body: JSON.stringify({
			content: payload.content,
			conversation: response.id,
			publisherCreator: payload.publisherReceiver,
			attachments: attachments.map((attachment) => attachment.id),
		}),
	});

	return await response;
};

export const sendMessage = async (payload) => {
	const attachments = await Promise.all(
		payload.files.map((file) => {
			const attachmentBody = new FormData();
			attachmentBody.append('file', file);

			return authorizedFetch(
				'attachments',
				{
					method: 'POST',
					body: attachmentBody,
					'Content-Type': undefined,
				},
				true
			);
		})
	);

	const response = await authorizedFetch('messages', {
		method: 'POST',
		body: JSON.stringify({
			content: payload.content,
			conversation: payload.conversationId,
			publisherCreator: payload.publisherCreator,
			attachments: attachments.map((attachment) => attachment.id),
		}),
	});

	return await response;
};
