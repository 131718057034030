import React, {useEffect} from 'react';
import jwt from "jwt-decode";
import {authActions} from "../../store/slice/authSlice";
import {useDispatch} from "react-redux";

const Authorized = (props) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const dispatch = useDispatch();

    useEffect(() => {
        if (refreshToken) {
            fetch(`${process.env.REACT_APP_API_URL}/auth/refresh_token`, {
                method: "POST",
                body: JSON.stringify({
                    refresh_token: refreshToken,
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(response => {
                if (response.ok) {
                    return response.json()
                }
            })
                .then(data => {
                    const user = jwt(data?.token)
                    dispatch(authActions.logIn(user))
                })
        }
    }, [])

    return (
        <>
            {props.children}
        </>
    );
};

export default Authorized;