import { createSlice } from '@reduxjs/toolkit';

const newMaterialSlice = createSlice({
	name: 'newMaterial',
	initialState: {
		licenceAttributes: [],
		chosenMainLicences: [],
		imageUrl: [],
	},
	reducers: {
		updateForm(state, action) {
			return (state = { ...state, ...action.payload });
		},
		updateImages(state, action) {
			state.imageUrl = [...state.imageUrl, action.payload.imageUrl];
		},
		daleteImage(state, action) {
			state.imageUrl = state.imageUrl.filter((e) => e !== action.payload);
		},
		daleteAllImages(state) {
			state.imageUrl = [];
		},
		daleteFormImage(state, action) {
			state.images = state.images.filter((e) => e.id !== action.payload.id);
		},
		daleteAllFormImages(state) {
			state.images = [];
		},
		resetForm(state) {
			return (state = {
				licenceAttributes: [],
				chosenMainLicences: [],
				imageUrl: [],
			});
		},
		updateLicenceAttributes(state, action) {
			state.licenceAttributes = action.payload;
		},
		updateMainLicenceAttributes(state, action) {
			const findIndex = state.chosenMainLicences.findIndex(
				(e) => e === action.payload
			);

			if (findIndex > 0) {
				state.chosenMainLicences.splice(findIndex, 1);
			} else {
				state.chosenMainLicences = [
					...state.chosenMainLicences,
					action.payload,
				];
			}
		},
	},
});

export const newMaterialActions = newMaterialSlice.actions;

export default newMaterialSlice.reducer;
