import React, {useState} from 'react';

const useInput = (validateValue, initialValue = "") => {
    const [enteredValue, setEnteredValue] = useState(initialValue);
    const [isTouched, setIsTouched] = useState(false);

    const isEnteredValueValid = validateValue(enteredValue);
    const hasError = !isEnteredValueValid && isTouched;


    const enteredValueChangeHandler = (e) => {
        setIsTouched(true)
        setEnteredValue(e.target.value);
    }

    const enteredValueSetInitial = (initialValue) => {
        setEnteredValue(initialValue);
    }

    const inputBlurHandler = () => {
        setIsTouched(true)
    }

    const reset = () => {
        setEnteredValue("")
        setIsTouched(false)
    }

    return {
        value: enteredValue,
        isTouched,
        hasError,
        enteredValueChangeHandler,
        inputBlurHandler,
        reset,
        enteredValueSetInitial
    }
};

export default useInput;