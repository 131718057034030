import {configureStore} from "@reduxjs/toolkit";
import authSlice from "./slice/authSlice";
import librarySlice from "./slice/librarySlice";
import newMaterialSlice from "./slice/newMaterialSlice";

const store = configureStore({
    reducer: {
        auth : authSlice,
        library: librarySlice,
        newMaterial: newMaterialSlice
    }
})


export default store;