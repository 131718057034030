import React from 'react';
import logo from '../../assets/img/Pobierający/logo.svg';
import { useQuery } from 'react-query';
import { getContents } from '../../api/get';

const Header = ({ setChosenMenuItem }) => {
	const contentsQuery = useQuery(['setting_contents'], getContents, {
		initialData: [],
		select: (data) =>
			data.reduce((acc, curr) => {
				acc[curr.insideKey] =
					curr.type === 'image' ? curr.file.contentUrl : curr.content;
				return acc;
			}, {}),
	});

	return (
		<>
			<header className="top">
				<div className="container">
					<div
						className="flex flex--space-between flex--center-y"
						style={{
							marginBottom: 20,
						}}
					>
						<div className="top__text">
							…niezwykłe wydarzenia na obrazach odsłoną prawdziwego Świata…
						</div>
						<div className="top__logo">
							<a href="https://gettimages.pl/">
								<img src={logo} alt="gettimages logo" />
							</a>
						</div>
						<div className="top__text top__text--last">
							…jesteśmy po to, aby przybliżać wyjątkowe chwile w realnym
							Świecie…
						</div>
					</div>
					<div
						className="mp-flex-wrapper__center mp-flex-main"
						style={{
							backgroundImage: `url('${contentsQuery.data['publisher.header.image']}')`,
							height: 'auto',
							minHeight: 300,
							margin: 0,
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'row',
							justifyContent: 'center',
						}}
					>
						<h1
							className="mp-flex-main__title"
							style={{
								marginBottom: 0,
							}}
						>
							{contentsQuery.data['publisher.header.text']}
						</h1>
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;
